<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="L['历史账单']"></MemberTitle>
            <div class="container">
                <h3>{{ L["历史账单"] }}</h3>
                <div class="sld_order_nav">
                    <div class="headerSearch">
                        <el-form ref="ruleFormRef" :model="param" :inline="true" class="demo-form-inline">
                            <el-form-item label="支付单号" prop="payCode">
                                <el-input v-model="param.payCode" placeholder="请输入支付单号" style="width: 260px" />
                            </el-form-item>
                            <el-form-item label="还款日期" prop="payStartTime">
                                <el-date-picker
                                    v-model="param.payStartTime"
                                    type="date"
                                    placeholder="开始时间"
                                    style="width: 190px"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                />
                                <!--</el-col>-->
                            </el-form-item>
                            <span>-</span>
                            <!--<el-col :span="11" prop="endDate">-->
                            <el-form-item prop="payEndTime">
                                <el-date-picker
                                    v-model="param.payEndTime"
                                    placeholder="结束时间"
                                    style="width: 190px"
                                    prop="payEndTime"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                />
                            </el-form-item>
                            <el-form-item label="账期时间" prop="accountPeriodDate">
                                <el-date-picker
                                    v-model="param.accountPeriodDate"
                                    type="month"
                                    placeholder="请输入账期时间"
                                    style="width: 260px"
                                    format="YYYY-MM"
                                    value-format="YYYY-MM"
                                />
                            </el-form-item>
                            <!--<el-col :span="11" prop="startDate">-->

                            <div class="searchBtn">
                                <div class="button" @click="onSubmit">搜索</div>
                                <el-button @click="resetForm(ruleFormRef)">重置</el-button>
                            </div>
                        </el-form>
                    </div>
                    <div></div>
                </div>
                <div class="sld_List">
                    <el-table :data="tableData.data" border max-width="250" header-cell-style="background:#FAFAFAFF;">
                        <el-table-column prop="accountPeriodDate" label="账期时间" width="160" align="center" />
                        <el-table-column prop="payCode" label="支付单号" width="170" align="center" />
                        <el-table-column prop="paymentWay" label="付款方式" width="100" align="center" />
                        <el-table-column
                            prop="orderSumNum"
                            label="订单总数"
                            max-height="60"
                            width="90"
                            align="center"
                        />
                        <el-table-column prop="shouldAccount" label="总金额" align="center" width="90px" />
                        <el-table-column prop="payTime" label="还款日期" width="165" align="center" />
                        <el-table-column prop="address" label="操作">
                            <template #default="scope">
                                <div style="display: flex; justifycontent: center">
                                    <p
                                        class="look"
                                        type="primary"
                                        size="small"
                                        style="margin-right: 10px"
                                        @click="toDetail(scope.row.periodCode)"
                                        link
                                    >
                                        查看详情
                                    </p>
                                    <p
                                        v-if="scope.row.paymentWay == '承兑支付'"
                                        class="look"
                                        type="primary"
                                        size="small"
                                        style="margin-right: 10px"
                                        @click="ViewAcceptance(scope.row.periodCode)"
                                        link
                                    >
                                        查看承兑凭证
                                    </p>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        style="margin-top: 10px"
                        :vi-if="param.total != 0"
                        v-model:current-page="param.pageNum"
                        v-model:page-size="param.pageSize"
                        :page-sizes="[10, 20, 30, 40]"
                        :small="small"
                        :disabled="disabled"
                        :background="background"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="param.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </div>
        </div>
        <div class="Model">
            <el-dialog v-model="isOpenModel" top="15%" width="35%" :before-close="closeDialog" title="查看承兑凭证">
                <!--<div>-->
                <!--<div class="my-header">
          <div class="modalTitle"><span> 查看承兑凭证</span></div>
          <div
            style="
              font-size: 24px;
              color: rgb(163 158 158);
              cursor: pointer;
              color: #fff;
            "
            @click="closeDialog"
          >
            ×
          </div>
        </div>-->
                <!--<div class="result">-->
                <div v-for="(item, index) in billList.data.acceptPayRecordReqList" :key="index">
                    <div class="result_Item">
                        <p class="result_title">票据号码：</p>
                        <p class="addBorder">{{ item.billCode }}</p>
                    </div>
                    <div class="result_Item">
                        <p class="result_title">票据金额：</p>
                        <p class="addBorder">{{ item.billAmount }}</p>
                    </div>
                    <div class="remark">
                        <span>备注：</span><span>{{ item.remark }}</span>
                    </div>
                </div>
                <!--</div>-->
                <!--</div>-->
            </el-dialog>
        </div>
        <!--<div class="chengdui-dialog">
      <el-dialog v-model="isOpenModel" title="填写承兑凭证" width="30%">
        <div class="cd-box">
          <div
            class="cd-item"
            v-for="(item, index) in billList.data.acceptPayRecordReqList"
            :key="index"
          >
            <div>
              <div>
                <span>票据号码：</span>
                <el-input
                  v-model="item.billCode"
                  maxlength="30"
                  placeholder="请输入票据号码"
                  autocomplete="off"
                />
              </div>
              <div class="line">
                <span>票据金额：</span>
                <el-input
                  v-model="item.billAmount"
                  oninput="value=value.match(/\d+\.?\d{0,2}/)"
                  placeholder="请输入票据金额"
                  autocomplete="off"
                />
              </div>
              <div class="line2">
                <span>备注：</span>
                <el-input
                  v-model="item.remark"
                   maxlength="200"
                  placeholder="请输入备注"
                  autocomplete="off"
                />
              </div>
            </div>
            <span style="padding-left:20px" @click="handleDelCd(index)">删除</span>
          </div>
        </div>
        <div class="addBtn">
          <div

            style="width: 367px;background:#0e3177;color:#fff;margin:0 auto;height:40px;line-height:40px;"
            @click="handleAddCd"
            >+ 新增</div
          >
        </div>
        <template #footer>
          <div class="el-dialog-footer">
            <span class="tx">请仔细核对信息是否无误 </span>
            <span class="dialog-footer">
              <el-button @click="chengduiVisible = false">取消</el-button>
              <el-button type="primary" @click="changeAccept">修改承兑信息</el-button>
            </span>
          </div>
        </template>
      </el-dialog>
    </div>-->
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import download from "downloadjs";
import { platform } from "@/utils/config";

export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const current_state = ref(""); //当前列表订单状态
        const evaluate_state = ref(0); //是否是待评价
        const ruleFormRef = ref();
        const orderSn = ref(""); //订单id
        let orderProductObj = reactive({});
        let orderProductList = reactive({ data: [] }); //确认收货展示数据
        const tableData = reactive({ data: [] });
        const isOpenModel = ref(false);
        const billList = reactive({ data: [] });
        const param = reactive({
            //memberId: store.state.memberInfo.memberId,
            payCode: "",
            accountPeriodDate: "",
            payStartTime: "",
            payEndTime: "",
            pageNum: 1,
            pageSize: 10,
            total: 0
        });

        const acceptData = reactive({
            billCode: "",
            billAmount: "",
            remark: ""
        });
        const onSubmit = () => {
            param.pageNum = 10;
            param.pageNum = 1;
            getOrderList();
            console.log("submit!");
        };
        const toDetail = (code) => {
            console.log(code);
            router.push({
                path: "/member/payment/HistoricalStatementDetail",
                query: {
                    periodCode: code
                }
            });
        };
        const resetForm = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            param.pageNum = 1;
            getOrderList();

            //param.resetFields()
            //param.planCode= "",
            // param. source= "",
            // param. prayName= "",
            // param. startDate= "",
            // param. endDate= "",
            //  param.resource= "",
            //  param.state= "",
            //getOrderList()
        };

        const getOrderList = () => {
            proxy.$post("api/shopp/accountPeriod/history", param, "json").then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    tableData.data = res.data.records;
                    if (res.data.records && res.data.records.length !== 0) {
                        param.pageSize = res.data.pageSize;
                        param.pageNum = res.data.pageNum;
                        param.total = res.data.total;
                        console.log(param.total);
                    }

                    //cartLen.value = res.data.count ? res.data.count : 0
                } else {
                    console.log(88888);
                    ElMessage.error(res.msg);
                }
            });
        };
        const addPlan = () => {
            router.push("/member/procurement/addPlan");
        };
        const handleSizeChange = (val) => {
            param.pageSize = val;
            getOrderList();
            console.log(`${val} items per page`);
        };
        const handleCurrentChange = (val) => {
            param.pageNum = val;
            getOrderList();
            console.log(`current page: ${val}`);
        };
        const ViewAcceptance = (code) => {
            isOpenModel.value = true;
            let params = {
                memberOrderCode: code,
                type: 2
            };
            proxy.$get("api/shopp/member/order/accept/pay/record", params, "json").then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    if (res.data.length !== 0) {
                        billList.data = {
                            periodCode: code,
                            acceptPayRecordReqList: res.data
                        };
                    } else {
                        let acceptPayRecordReqList = [
                            {
                                billAmount: "",
                                billCode: "",
                                //memberOrderCode: code,
                                //paymentNumber: code,
                                remark: ""
                            }
                        ];
                        billList.data = {
                            periodCode: code,
                            acceptPayRecordReqList: acceptPayRecordReqList
                        };
                    }
                } else {
                    console.log(88888);
                    ElMessage.error(res.msg);
                }
            });
        };
        const closeDialog = () => {
            isOpenModel.value = false;
        };
        //承兑新增凭证
        const handleAddCd = () => {
            let item = { billCode: null, billAmount: null };
            billList.data.acceptPayRecordReqList.push(item);
        };
        //承兑删除凭证
        const handleDelCd = (index) => {
            if (billList.data.acceptPayRecordReqList.length == 1) {
                return ElMessage.warning("至少保留一张凭证");
            }
            billList.data.acceptPayRecordReqList.splice(index, 1);
        };
        const changeAccept = () => {
            let params = billList.data;
            proxy.$post("api/shopp/accountPeriod/confirmAccept", params, "json").then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    ElMessage("修改成功");
                    isOpenModel.value = false;
                } else {
                    console.log(88888);
                    ElMessage.error(res.msg);
                }
            });
        };

        onMounted(() => {
            if (route.query.orderState) {
                current_state.value = route.query.orderState;
            } else {
                current_state.value = "";
            }
            if (route.query.evaluateState) {
                evaluate_state.value = route.query.evaluateState;
            } else {
                evaluate_state.value = "";
            }
            //  getTime();
            getOrderList();
            //  getCancelList();
            //  getAddressList();
        });

        router.beforeEach((to, from, next) => {
            //  if (to.query.orderState) {
            //    current_state.value = to.query.orderState;
            //  } else {
            //    current_state.value = "";
            //  }
            //  if (to.query.evaluateState) {
            //    evaluate_state.value = to.query.evaluateState;
            //  } else {
            //    evaluate_state.value = "";
            //  }
            if (to.path == "/member/procurement/procurementPlanList") {
                getOrderList();
            }
            next();
        });
        return {
            L,
            getOrderList,
            onSubmit,
            param,
            tableData,
            toDetail,
            addPlan,
            resetForm,
            ruleFormRef,
            acceptData,
            handleSizeChange,
            handleCurrentChange,
            ViewAcceptance,
            isOpenModel,
            closeDialog,
            billList,
            handleDelCd,
            handleAddCd,
            changeAccept
        };
    }
};
</script>
<style lang="scss" scoped>
@import "../../../style/orderList.scss";
/*@import "../../../style/historiclStatement.scss";*/

::v-deep .el-dialog__header {
    background: #0e3177;
    color: #fff;
    .el-dialog__title {
        color: #fff;
    }
    .el-icon {
        color: #fff;
    }
}

.sld_order_list .container {
    width: 1018px;
}
.el-pager li.active {
    color: $colorMain;
    cursor: default;
    font-size: 16px;
}
.look {
    color: rgb(74, 122, 211);
    cursor: pointer;
}
.el-pager li:hover {
    color: $colorMain;
}

.el-select-dropdown__item.selected {
    color: $colorMain;
}

.sld_order_list {
    color: #000;
    .sld_List {
        color: #000;
    }
    /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
    .aft_btn:hover {
        background-color: rgba($color: #f30213, $alpha: 0.2);
        color: #f30213;
    }
    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }
    .dia_btn {
        .el-button {
            width: 134px;
            height: 36px;
        }
    }
    .downLoad_contract {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e8e8f1;
        border-radius: 5px;
        cursor: pointer;
    }
    .downLoad_contract:hover {
        border: 1px solid $colorMain;
    }
}
.container {
    .bootom-footer {
        text-align: right;
    }
    .bootom-footer button:first-child {
        margin-right: 10px;
    }
    // .el-input {
    //   width: 300px;
    // }
    .unit {
        font-size: 18px;
        margin-left: 10px;
    }
}
.demo-form-inline .el-input {
    --el-input-width: 220px;
}
.el-table thead {
    color: #5f5f60;
}
.el-table {
    color: #2b2b2c;
}
.sld_order_button {
    margin-bottom: 10px;
}
.el-radio {
    margin-right: 0 !important;
}
.button {
    background: #0e3177;
    cursor: pointer;
    width: 70px;
    height: 40px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    /*margin-left: 10px;*/
    line-height: 40px;
    font-size: 14px;
    margin-right: 10px;
}
.headerSearch {
    position: relative;
}
.searchBtn {
    cursor: pointer;
    display: flex;
    width: 200px;
    position: absolute;
    top: 0%;
    left: 85%;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    /*background: #03499e;*/
}
.el-dialog__header {
    display: none;
}
.el-dialog__body {
    padding: 0;
}
.dialog {
    .dialog_title {
        width: 100%;
        height: 44px;
        background: #0e3177;
        padding: 12px 14px;
        color: #ffffff;
        span {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
        }
        i {
            font-size: 14px;
            cursor: pointer;
        }
    }
    .dialog_info {
        width: 400px;
        margin-top: 20px;
        .tips_p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #b5b5b5;
            margin-top: 5px;
        }
        .dialog_info_tit {
            width: 100px;
            line-height: 36px;
            flex-shrink: 0;
            color: #121212;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            text-align: right;
            white-space: nowrap;
            margin-right: 6px;
            .xing {
                position: relative;
                &:before {
                    content: "*";
                    position: absolute;
                    left: -7px;
                    color: #e2231a;
                }
            }
        }
    }
    .el-input__inner {
        width: 290px;
        font-weight: 400;
        font-size: 14px;
        font-family: Microsoft YaHei;
    }
    .el-textarea__inner {
        width: 290px;
        min-height: 138px !important;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .dialog_checkbox {
        width: 700px;
        padding: 20px;
        // .dialog_checkbox_group {}
        .dialog_checkbox_item {
            margin-left: 24px;
            flex-wrap: wrap;
        }
        .el-checkbox {
            margin-bottom: 16px;
        }
    }
    .dialog_btn {
        width: 440px;
        border-top: 1px solid #f2f2f2;
        padding: 15px 20px;
        &.dialog_btn_auth {
            width: 700px;
        }
        .dialog_close,
        .dialog_submit {
            width: 78px;
            height: 36px;
            line-height: 36px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            text-align: center;
            border-radius: 6px;
            margin-left: 12px;
            cursor: pointer;
        }
        .dialog_close {
            color: #666262;
            background: #eeeeee;
        }
        .dialog_submit {
            color: #ffffff;
            background: $colorMain;
        }
    }
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #e3e2e2;
    padding: 13px 20px 0px 15px;
    background: #0e3177;
    /*width: 100%;*/
    /*margin-top:10px;*/
    padding-bottom: 10px;
    color: #fff;
}
.el-dialog__header {
    padding: 0px;
    padding-bottom: 0px;
}
.modalTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    img {
        width: 27px;
        height: 24px;
        /*margin-right: 5px;*/
    }
}
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px !important;
    padding-left: 0px;
    padding-right: 0px;
}
.form-Input {
    margin-bottom: 53px;
}
/*.el-dialog {
  border-radius: 5px;
}*/
.result {
    height: 100%;
    margin-top: 30px;
    text-align: left;
    padding: 0px 20px 20px 20px;
    font-size: 18px;
    line-height: 1.5;
    margin-left: 20px;
}
.el-button--primary {
    --el-button-background-color: #0e3177;
    --el-button-border-color: #0e3177;
    --el-button-hover-color: #0e3177;
    --el-button-background-color: #0e3177;
    --el-button-border-color: #0e3177;
    --el-button-hover-color: #0e3177;
}
.chengdui-dialog {
    .cd-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .cd-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            .line {
                margin-top: 13px;
            }
            .line2 {
                margin-top: 13px;
                margin-left: 25px;
            }
            .el-input {
                width: 297px;
            }
            i {
                font-size: 20px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
    .addBtn {
        text-align: center;
        margin-top: 24px;
    }
    .el-dialog-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .tx {
            color: #ff0000;
            font-size: 15px;
            margin-bottom: 10px;
        }
    }
    .el-dialog__header {
        border-bottom: 1px solid #ccc;
    }
}
.result_Item {
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    margin-bottom: 10px;
}
.addBorder {
    border: 1px solid #ccc;
    display: inline-block;
    height: 40px;
    width: 450px;
    line-height: 40px;
    border-radius: 5px;
    padding-left: 10px;
}
.result_title {
    width: 120px;
}
.remark {
    margin-bottom: 10px;
}
.blue {
    /*padding-top: 0px !important;
    padding-bottom: 20px !important;*/
    background: #0e3177 !important;
    color: #fff;
}
</style>
<style lang="scss">
.headerSearch {
    .el-form--inline .el-form-item {
        margin-left: 10px;
    }
}
</style>
